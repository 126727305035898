/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";

import { Layout, Button, Table, DatePicker, Modal, Row, Col, Input, Card, Space, Tag, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import useAuth from "hooks/useAuth";
import { getManualInvoiceUsers, userDelete } from "apis/user";

import moment from "moment";

import "./styles.less";

const { RangePicker } = DatePicker;

const ManualInvoices = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState([]);

  const searchInputEl = useRef(null);
  const [, setUsers] = useState([]);

  const [usersFilter, setUsersFilter] = useState([]);
  const [expandedKey, setExpandedKey] = useState([]);
  const onExpand = (_, { userId }) => expandedKey.includes(userId) ? setExpandedKey(prev => prev.filter(item => item !== userId)) : setExpandedKey(prev => [...prev, userId]);

  const [searchText, setSearchText] = useState("");
  const [, setSearchedColumn] = useState("");

  const [fromDate, setFromDate] = useState(moment().subtract(3, "months"));
  const [toDate, setToDate] = useState(moment());

  useEffect(() => {
    (async () => {
      try {
        if (user) {
          setLoading(true);

          const payload = {
            fromDate: fromDate.format("YYYY-MM-DD"),
            toDate: toDate.format("YYYY-MM-DD"),
          };
          const [result] = await Promise.all([
            getManualInvoiceUsers(payload),
          ]);

          setUsers(result);
          setUsersFilter(result?.filter(x => x.seat >= 1));
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    })();
  }, [user]);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInputEl}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => {
      const [firstValue, ...restValue] = value;

      if (dataIndex === "usernameReferral" && firstValue === "!") {
        return record[dataIndex]
          ? !record[dataIndex].toString().toLowerCase().includes(restValue.join("").toLowerCase())
          : true;
      }

      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputEl.current.select(), 100);
      }
    },
    render: text => {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <div>
              {/* {(dataIndex === "email" && text?.appsumo !== undefined) && (
                <span style={{ fontWeight: "bold", marginRight: 5, color: "orange" }}>[AppSumo]</span>
              )} */}
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text[dataIndex] ? (dataIndex === "source" ? `${text[dataIndex].charAt(0).toUpperCase()}${text[dataIndex].slice(1)}`.toString() : text[dataIndex].toString()) : ""}
              />
            </div>
          </div>
        </div>
      )
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };

  function onChange(dates, dateStrings) {
    if (!dates) {
      setFromDate(undefined);
      setToDate(undefined);
    }

    if (dates && dates[0]) {
      setFromDate(dates[0])
    }

    if (dates && dates[1]) {
      setToDate(dates[1])
    }
  }

  const columns = [
    {
      title: "Country",
      align: "center",
      width: "5%",
      ...getColumnSearchProps("countryCode"),
    },
    {
      title: "Created At",
      align: "center",
      dataIndex: "createdAt",
      width: "10%",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      defaultSortOrder: "descend",
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.createdAt).format("YYYY-MM-DD")}</div>
          </div>
        );
      }
    },
    {
      title: "Email",
      width: "30%",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Source",
      width: "8%",
      ...getColumnSearchProps("source"),
    },
    {
      title: "Role",
      align: "center",
      render: (record) => {
        console.log("record: ", record)
        if (record?.role === "free") {
          return (
            <Tag>{record?.role?.toUpperCase()}</Tag>
          );
        } else {
          return (
            <Tag color="green">{record?.role?.toUpperCase()}</Tag>
          );
        }
      },
      width: "8%",
      filterMultiple: true,
      filters: [
        {
          text: "Free",
          value: "free",
        },
        {
          text: "Trial",
          value: "trial",
        },
        {
          text: "Pre",
          value: "pre",
        },
      ],
      onFilter: (value, record) => record.role === value,
    },
    {
      title: "Seat",
      align: "center",
      render: (record) => {
        return <div>{record?.seat}</div>
      },
      width: "8%",
    },
    {
      title: "EndTime",
      align: "center",
      dataIndex: "endTime",
      width: "10%",
      sorter: (a, b) => moment(a.endTime).unix() - moment(b.endTime).unix(),
      defaultSortOrder: "descend",
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.endTime).format("YYYY-MM-DD")}</div>
          </div>
        );
      }
    },
    {
      title: "Expired",
      align: "center",
      dataIndex: "isAfter",
      render: (record) => {
        if (record === false) {
          return <Tag color="red">Expired</Tag>;
        }
      },
      width: "8%",
      filterMultiple: true,
      filters: [
        {
          text: "Expired",
          value: false,
        },
      ],
      onFilter: (value, record) => record.isAfter === value,
    },
    {
      width: "8%",
      key: "action",
      render: (record) =>
        <div>
          {(user?.role === "super") && (
            <>
              <Button
                style={{ color: "red" }}
                type="link"
                ghost
                onClick={() => {
                  Modal.confirm({
                    title: "Confirm",
                    content: "Are you sure to delete this user?",
                    okText: "Ok",
                    cancelText: "Không",
                    onOk: () => {
                      let { userId, email } = record;
                      let payload = {
                        userId,
                        email,
                      }
                      userDelete(payload)
                        .then(async (result) => {
                          setLoading(true)
                          message.success("This user has been deleted successfully")
                          setLoading(false)
                        })
                        .catch(err => console.log(err))
                    }
                  });
                }}
              >Delete</Button>
            </>
          )
          }
        </div>
    },
  ];

  const columnsUser = [
    {
      title: "Created At",
      align: "center",
      dataIndex: "createdAt",
      width: "10%",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      defaultSortOrder: "descend",
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.createdAt).format("YYYY-MM-DD HH:mm")}</div>
          </div>
        );
      }
    },
    {
      title: "Email",
      width: "35%",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Role",
      align: "center",
      dataIndex: "role",
      render: (record) => {
        if (record === "free") {
          return <Tag>{record.toUpperCase()}</Tag>;
        } else {
          return <Tag color="green">{record.toUpperCase()}</Tag>;
        }
      },
      width: "8%",
      filterMultiple: true,
      filters: [
        {
          text: "Free",
          value: "free",
        },
        {
          text: "Trial",
          value: "trial",
        },
        {
          text: "Pre",
          value: "pre",
        },
      ],
      onFilter: (value, record) => record.role === value,
    },
    {
      title: "EndTime",
      align: "center",
      dataIndex: "endTime",
      width: "10%",
      sorter: (a, b) => moment(a.endTime).unix() - moment(b.endTime).unix(),
      defaultSortOrder: "descend",
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record?.endTime).format("YYYY-MM-DD")}</div>
          </div>
        );
      }
    },
  ];

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }} >
        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={12}>
              <h2>Manual Invoices</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <RangePicker
                ranges={{
                  "Today": [moment(), moment()],
                  "Yesterday": [moment().subtract(1, "days"), moment().subtract(1, "days")],
                  "This Week": [moment().startOf("week"), moment().endOf("week")],
                  "This Month": [moment().startOf("month"), moment().endOf("month")],
                  "This Quarter": [moment().startOf("quarter"), moment().endOf("quarter")],
                  "This Year": [moment().startOf("year"), moment().endOf("year")],
                }}
                onChange={onChange}
                defaultValue={[moment().subtract(3, "months"), moment()]}
                allowClear={false}
              />

              <Button
                type="primary"
                style={{ marginLeft: 24 }}
                icon={<SearchOutlined />}
                disabled={!fromDate || !toDate}
                onClick={() => {
                  setLoading(true);

                  const payload = {
                    fromDate: fromDate.format("YYYY-MM-DD"),
                    toDate: toDate.format("YYYY-MM-DD"),
                  };

                  getManualInvoiceUsers(payload)
                    .then(result => {
                      setUsersFilter(result);
                      setLoading(false);
                    })
                    .catch(err => {
                      setLoading(false);
                    })
                }}
              >
                Search
              </Button>
            </Col>
          </Row>
        </Card>

        <Table
          loading={loading}
          bordered
          columns={columns.filter(column => {
            if (column.key === "action") {
              if (user?.role === "super" || user?.role === "sale") {
                return true;
              }
              return false;
            }
            return true;
          })}
          dataSource={usersFilter}
          pagination={{
            defaultPageSize: 20
          }}
          rowKey={(row) => row.userId}
          expandable={{
            expandedRowRender: (record) => (
              <Table
                loading={loading}
                bordered
                columns={columnsUser}
                dataSource={record.teams}
                pagination={false}
              />
            ),
            onExpand: onExpand,
            expandedRowKeys: [...expandedKey]
          }}
        />
      </Layout>
    </Layout>

  )
};

export default ManualInvoices;