import React, { useState, useEffect } from 'react';

import { Layout, Button, Table, DatePicker, Row, Col, Card, Rate } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { getSurveys } from 'apis/surveys';
import moment from 'moment';

import './styles.less';

const { RangePicker } = DatePicker;

const Surveys = () => {
  const [loading, setLoading] = useState([]);

  const [reports, setReports] = useState([]);
  const [reportsFilter, setReportsFilter] = useState([]);

  const [fromDate, setFromDate] = useState(moment().subtract(7, 'days'));
  const [toDate, setToDate] = useState(moment());

  const [npsScore, setNpsScore] = useState(0);

  const npsScores = (scores) => {
    var promoters = 0;
    var detractors = 0;

    for (var i = 0, l = scores.length; i < l; i++) {
      if (scores[i] >= 9) promoters++;
      if (scores[i] <= 6) detractors++;
    }

    return Math.round(((promoters / l) - (detractors / l)) * 100);
  }

  useEffect(() => {
    (async () => {
      try {
        //fetch data
        setLoading(true)
        let payload = { fromDate: fromDate.format("YYYY-MM-DD"), toDate: toDate.format("YYYY-MM-DD") };
        const [result] = await Promise.all([
          getSurveys(payload),
        ]);

        setReports(result)
        setReportsFilter(result)
        setLoading(false)

        let npsScore = npsScores(result.map(item => parseInt(item?.rating)))
        setNpsScore(npsScore);

      } catch (e) {
        setLoading(false)
      }
    })();
  }, [fromDate, toDate])

  function onChange(dates, dateStrings) {
    if (!dates) {
      setFromDate(null);
      setToDate(null);
    }

    if (dates && dates[0]) {
      setFromDate(dates[0])
    }

    if (dates && dates[1]) {
      setToDate(dates[1])
    }
  }

  const columns = [
    {
      title: 'Created At',
      align: 'center',
      dataIndex: 'createdAt',
      width: '5%',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.createdAt).format('YYYY-MM-DD')}</div>
          </div>
        );
      }
    },
    {
      title: 'User',
      dataIndex: 'user',
      width: '4%',
      render: (record) => {
        return (
          <div>
            <div>{record?.email}</div>
          </div>
        );
      },
    },
    {
      title: 'Rating (1 - 10)',
      dataIndex: 'rating',
      align: 'center',
      render: (record) => {
        return <div>
          <Rate allowHalf value={parseInt(record) / 2} />
        </div>
      },
      width: '10%',
      filters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map(item => {
        return {
          text: item,
          value: item
        }
      }),
      onFilter: (value, record) => record?.rating === value.toString(),
    },
    {
      title: 'Feedback',
      dataIndex: 'feedback',
      render: (record) => {
        return <div>{record}</div>
      },
      width: '40%',
    },
  ];

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }} >
        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={12}>
              <h2>Surveys</h2>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <RangePicker
                ranges={{
                  Today: [moment(), moment()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                }}
                onChange={onChange}
                defaultValue={[fromDate, toDate]}
                allowClear
              />
              <Button
                type="primary"
                style={{ marginLeft: 24 }}
                icon={<SearchOutlined />}
                disabled={!fromDate || !toDate}
                onClick={() => {
                  setLoading(true)
                  let payload = {
                    fromDate: fromDate.format('YYYY-MM-DD'),
                    toDate: toDate.format('YYYY-MM-DD')
                  };

                  getSurveys(payload)
                    .then(result => {
                      setReports(result)
                      setReportsFilter(result)

                      let npsScore = npsScores(result.map(item => parseInt(item?.rating)))
                      setNpsScore(npsScore);

                      setLoading(false)
                    })
                    .catch(err => {
                      setLoading(false)
                    })
                }}
              >Search</Button>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <strong>NPS Score</strong>: {npsScore}
            </Col>
          </Row>
        </Card>
        <Table
          loading={loading}
          bordered
          columns={columns}
          dataSource={reportsFilter}
          pagination={{
            defaultPageSize: 20
          }}
        />
      </Layout>
    </Layout>
  )
};

export default Surveys;