import { db } from 'utils/firebase';
import { Timestamp, collection, query, orderBy, where, getDocs } from "firebase/firestore";

export const getSurveys = async (payload) => {
  try {
    let q = query(collection(db, "surveys"), orderBy('createdAt', 'desc'));

    //fromDate, toDate
    if (payload.fromDate) {
      let start = Timestamp.fromDate(new Date(payload.fromDate))
      q = query(q, where('createdAt', '>', start));
    }
    if (payload.toDate) {
      let end = Timestamp.fromDate(new Date(new Date(`${payload.toDate} 23:59:59`)))
      q = query(q, where('createdAt', '<', end));
    }

    const querySnapshot = await getDocs(q);

    let items = [];
    querySnapshot.forEach((doc) => {
      items.push({
        surveyId: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate(),
        user: {
          ...doc.data().user,
          createdAt: doc.data().user?.createdAt?.toDate(),
        }
      });
    });
    return items;
  } catch (error) {
    console.log(error)
    return []
  }
};